import React from 'react';
import Button from '../components/Button';
import { Column, Container, Row } from '../components/Grid';
import Icon from '../components/Icon';
import Image from '../components/Image';
import Layout from '../components/Layout';
import OutboundLink from '../components/OutboundLink';
import Section from '../components/Section';
import Subfooter from '../components/Subfooter';
import Video from '../components/Video';
import Resource from '../components/Resource';
import { Link } from 'gatsby';
import bgImage from '../images/CPdetail-image-videostill-bkgd-Kim.jpg';
import '../styles/styles.scss';

const MSCareerProfile = () => (
  <Layout title="Health Equity Careers | Manufacturing Scientist">
    <Section className="about-mission">
      <Container>
        <Button
          link
          to="/career-profiles"
          className="individual-profile__return"
        >
          &#60; View All
        </Button>
        <h1 className="h1 text-bold">Health Equity Careers</h1>
      </Container>
    </Section>
    <Section className="pt-0">
      <Container fullWidth>
        <Row>
          <Column>
            <div
              className="career-profile__video-wrapper"
              style={{ backgroundImage: `url('${bgImage}')` }}
            >
              <div className="career-profile__link-container">
                <Link className="career-profile__link" to="/health-worker">
                  <Icon name="arrowleft" />
                </Link>
              </div>
              <div className="career-profile__video-container">
                <Video guidSrc="78022327-3eb8-4efa-87a3-f48fa4b165f0" />
              </div>
              <div className="career-profile__link-container">
                <Link
                  className="career-profile__link"
                  to="/manufacturing-scientist"
                >
                  <Icon name="arrowright" />
                </Link>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="pt-0 pb-0">
      <Container>
        <Row>
          <Column size={8}>
            <h2 className="h2 mt-1">Patient Screening Operations Manager</h2>
            <p>
              Learn more about this career by exploring the in-depth career fact
              sheet and engage students with hands-on activities for any
              environment.
            </p>
            {/* Quick links */}
            <div className="individual-profile__links">
              Quick Links:&nbsp;
              <Button link to="#about">
                About
              </Button>
              |
              <Button link to="#evaluate">
                Evaluate Your Interest
              </Button>
              |
              <Button link to="#activate">
                Activate Your Interest
              </Button>
            </div>

            <div>
              <Resource
                title="About"
                description="The Patient Screening Operations Manager screens incoming patients to understand their individual needs and challenges. Part social worker and part healthcare provider, Krishna works across departments to overcome social determinants of health affecting her patients and their community, whether that means support with utilities, housing, or helping patient plan and source a healthier diet."
                actions={[
                  {
                    label: 'Career Profile',
                    type: 'dropdown',
                    actions: [
                      {
                        label: 'English',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-Career Profile-OperationsManager-V1.0.pdf',
                        type: 'download',
                        title: 'Operations Manager Career Profile-English',
                      },
                    ],
                  },
                ]}
              />
            </div>
            <div className="individual-profile__evaluate mt-1">
              <h3 className="h3" id="evaluate">
                Evaluate Your Interest
              </h3>
              <p>
                Find out more about this career and see how it aligns with
                student interests.
              </p>
              <ul>
                <li>
                  Highly empathetic toward different people and their individual
                  circumstances.
                </li>
                <li>
                  Big-picture thinking is necessary to develop treatment plans
                  that take social determinants of health into account.
                </li>
                <li>
                  Champion the needs of those who are unable or unused to
                  advocating for themselves.
                </li>
                <li>
                  Strong communication skills to convey to healthcare providers
                  the needs of new patients.
                </li>
              </ul>
            </div>
            <div className="individual-profile__activate">
              <Resource
                title="Activate Your Interests"
                description="Bring this career to life with a classroom activity that
              empowers students to identify health inequities in their own
              communities and promote health equity awareness. This activity
              is designed as a companion to the video profile."
                actions={[
                  {
                    label: 'Student Activation',
                    type: 'dropdown',
                    actions: [
                      {
                        label: 'English',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-PatientScreeningOperationsManager-V1.0.pdf',
                        type: 'download',
                        title: 'Operations Manager Student Activation-English',
                      },
                    ],
                  },
                ]}
              />
            </div>
          </Column>
          {/* Side column */}
          <Column size={4} className="pl-3 pt-4 pr-3">
            <Image
              filename="global-image-headshot-Krishna@2x.png"
              className="careerProfiles__headshot"
            />
            <div className="individual-profile__holder">
              <div className="individual-profile__quote">
                “I didn’t know that I’d be in healthcare, but I always know that
                I like to advocate for people— that I like to be a voice.”
                <div className="mt-1">Krishna Varela-Poole</div>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="pt-0">
      <Container>
        <Row style={{ justifyContent: 'center' }}>
          <Column size={8}>
            <Image filename="global-languages-promo-box@2x.png" />
          </Column>
        </Row>
        <Row className="mt-2">
          <Column size={12}>
            <h3 className="h3">Additional Careers to Explore</h3>
            <p>
              Discover the many exciting STEM careers that help support health
              equity globally. Learn what inspires these diverse individuals by
              exploring the below Career Profiles.
            </p>
          </Column>
        </Row>
        <Row className="mt-2">
          <Column size={6} className="pr-1">
            <OutboundLink to="/patient-affairs">
              <Image filename="CPdetail-image-careers-vp.jpg" />
            </OutboundLink>
            <h3 className="h3">
              Vice President and Head of the Center
              <br />
              for Health Equity and Patient Affairs
            </h3>
            <p>
              Find out what is needed to oversee a Center of Excellence focused
              on addressing health inequities from a doctor who is driven by a
              lifelong passion to provide care for others.
            </p>
            <Button to="/patient-affairs" className="button--link">
              Learn more <Icon name="arrowright" marginLeft />
            </Button>
          </Column>
          <Column size={6} className="pl-1">
            <OutboundLink to="/pharmaceutical-sciences">
              <Image filename="CPdetail-image-careers-AssocDir.jpg" />
            </OutboundLink>
            <h3 className="h3">
              Associate Director of Pharmaceutical Sciences
            </h3>
            <p>
              Learn how advanced technologies like robotics and artifical
              intelligence are being incorporated into the process of providing
              better healthcare to patients.
            </p>
            <Button to="/pharmaceutical-sciences" className="button--link">
              Learn more <Icon name="arrowright" marginLeft />
            </Button>
          </Column>
        </Row>
      </Container>
    </Section>

    <Subfooter
      className="subfooter__quote-careers-no-quotes"
      textRight
      textRed
      footerImage="CPdetail-kim-image-QuoteBox.jpg"
      footerText="Delivering high-quality, innovative medicines and products, to patients when and where they need them."
    />
  </Layout>
);

export default MSCareerProfile;
